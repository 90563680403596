<template>
  <!-- <svg fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.01 4.555 4.005 7.11 8.01 9.665 4.005 12.22 0 9.651l4.005-2.555L0 4.555 4.005 2 8.01 4.555Zm-4.026 8.487 4.006-2.555 4.005 2.555-4.005 2.555-4.006-2.555Zm4.026-3.39 4.005-2.556L8.01 4.555 11.995 2 16 4.555 11.995 7.11 16 9.665l-4.005 2.555L8.01 9.651Z" fill="currentColor" />
  </svg> -->
  <!-- <svg xmlns="http://www.w3.org/2000/svg" width="43px" viewBox="0 0 43 40" version="1.1" height="40px">
    <path d="m12.5 0l-12.5 8.1 8.7 7 12.5-7.8-8.7-7.3zm-12.5 21.9l12.5 8.2 8.7-7.3-12.5-7.7-8.7 6.8zm21.2 0.9l8.8 7.3 12.4-8.1-8.6-6.9-12.6 7.7zm21.2-14.7l-12.4-8.1-8.8 7.3 12.6 7.8 8.6-7zm-21.1 16.3l-8.8 7.3-3.7-2.5v2.8l12.5 7.5 12.5-7.5v-2.8l-3.8 2.5-8.7-7.3z" fill="#007EE5"/>
  </svg> -->
  <svg viewBox="0 0 24 24" version="1" xmlns="http://www.w3.org/2000/svg">
    <path fill="#fff" d="m3 12.9 5.3 3.5 3.7-3.1L6.7 10zm5.3-9.3L3 7.1 6.7 10 12 6.7zM21 7.1l-5.3-3.5L12 6.7l5.3 3.3zm-9 6.2 3.7 3.1 5.3-3.5-3.7-2.9zm0 1.2-3.7 3.1-1.6-1.1v1.2l5.3 3.2 5.3-3.2v-1.2l-1.6 1.1z" />
  </svg>
</template>
