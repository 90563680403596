import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './utilities/axiosInterceptor'

// Library Components
import VueSweetalert2 from 'vue-sweetalert2'
import BootstrapVue3 from 'bootstrap-vue-3'
import Vue3EasyDataTable from 'vue3-easy-data-table'
import Vue3Toasity from 'vue3-toastify'

// Custom Components & Directives
import globalComponent from './plugins/global-components'
import globalDirective from './plugins/global-directive'
import globalMixin from './plugins/global-mixin'
import 'vue3-easy-data-table/dist/style.css'
import 'vue3-toastify/dist/index.css'
import 'aos/dist/aos.css'

require('waypoints/lib/noframework.waypoints.min')

const app = createApp(App)
app.use(store).use(router)

app.use(VueSweetalert2)
app.use(BootstrapVue3)
app.use(Vue3Toasity, {
  autoClose: 2500,
  style: {
    opacity: '1',
    userSelect: 'initial'
  },
  position: 'bottom-left',
  limit: 3
})

app.component('EasyDataTable', Vue3EasyDataTable)

// Custom Components & Directives
app.use(globalComponent)
app.use(globalDirective)
app.mixin(globalMixin)

app.mount('#app')

export default app
